import { http_client_with_status } from "@/plugins/http_client";

const cargarResumenInstitucional = (filtros) => http_client_with_status("/api/v1/reporte-planificacion-pac/resumen-institucional", filtros, "get");

const cargarResumenMensual = (filtros) => http_client_with_status("/api/v1/reporte-planificacion-pac/resumen-mensual", filtros, "get");

const cargarResumenPorModalidad = (filtros) => http_client_with_status("/api/v1/reporte-planificacion-pac/resumen-por-modalidades", filtros, "get");

const cargarProcesosConPlanificacion = (filtros) => http_client_with_status("/api/v1/reporte-planificacion-pac/procesos-con-planificacion", filtros, "get");

export default {
    cargarResumenInstitucional,
    cargarResumenMensual,
    cargarResumenPorModalidad,
    cargarProcesosConPlanificacion,
}
