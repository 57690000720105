<template>
  <v-menu offset-y style="z-index: 999">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" dark v-bind="attrs" v-on="on">
        {{ titulo }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(elemento, index) in menu"
        :key="index"
        @click="elemento.callback1 ? ejecutarAccionObjeto(elemento.callback1) : ejecutarAccionId(elemento.callback2)"
        :disabled="elemento.disabled"
        :class="elemento.disabled ? 'disabled-menu' : ''"
      >
        <v-list-item-icon v-if="elemento.icono">
          <v-icon>{{ elemento.icono }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ elemento.nombre }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "MenuAcciones",
  props: {
    menu: {
      type: Array,
      required: true,
    },
    titulo: {
      type: String,
      required: false,
      default: "Acciones disponibles",
    },
    elemento: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "secondary",
    },
  },
  methods: {
    ejecutarAccionObjeto(accion) {
      if(this.elemento.disabled) return;
      accion(this.elemento);
    },
    ejecutarAccionId(accion) {
      if(this.elemento.disabled) return;
      accion(this.elemento.id);
    },
  },
};
</script>
<style scoped>
.disabled-menu {
  background: rgb(170, 162, 162);
}
.enabled-menu {
  background: aqua;
}
</style>
