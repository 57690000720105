<template>
  <v-row no-gutters class="d-flex align-center">
    <v-col cols="12" md="auto" class="d-flex px-2 pt-2" order="1" order-md="4">
      <!-- icono de menu -->
      <v-icon
        class="mr-5"
        v-if="$vuetify.breakpoint.smAndDown"
        @click="toggleDrawer"
      >
        mdi-menu
      </v-icon>

      <v-spacer />
      <v-img
        class="mt-2"
        v-if="$vuetify.breakpoint.sm"
        :src="require(`@/assets/img/goes_horizontal.png`)"
        height="50"
        width="50"
        contain
      />
      <v-spacer v-if="!$vuetify.breakpoint.xs" />

      <!-- notificaciones -->
      <v-menu
        offset-y
        transition="scroll-y-transition"
        rounded="lg"
        :close-on-content-click="false"
        style="z-index: 300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-2" v-bind="attrs" v-on="on" icon>
            <v-badge
              :content="notificaciones_pendientes"
              :value="notificaciones_pendientes"
              color="red"
              overlap
            >
              <v-icon> mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <NotificacionesComponent @reloadNotifications="getNotificaciones()" />
      </v-menu>

      <!-- Carrito de  compras -->
      <CartComponent v-if="hasAccessToCart" />
      <FeedbackModalComponent
        v-bind="feedbackModal"
        @on-close="handleFeedbackClose"
      />

      <!-- logout -->
      <v-menu
        offset-y
        transition="scroll-y-transition"
        rounded="lg"
        style="z-index: 300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" v-bind="attrs" v-on="on" elevation="" icon>
            <!-- {{ userInfo.user.email }} -->
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list dense nav>
          <v-list-item two-line class="my-0">
            <v-list-item-content>
              <v-list-item-title>{{ userInfo?.user?.email }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ cargoSelectedUnidad }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'profile' }" class="my-0" v-if="false">
            <v-list-item-icon>
              <v-icon size="20">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showTopCreate = true" class="my-0">
            <v-list-item-icon>
              <v-icon size="20">mdi-shield-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Agregar authenticator</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="showPasswordDialog = true"
            class="my-0"
            v-if="haveRole('ROLE_PROVEEDOR_EMPLEADO') || !haveRole('ROLE_PROVEEDOR_EMPLEADO')"
          >
            <v-list-item-icon>
              <v-icon size="20">mdi-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cambiar contraseña</v-list-item-title>
          </v-list-item>
          <v-list-item @click="cerrarSession()" class="my-0">
            <v-list-item-icon>
              <v-icon size="20">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item>
          <v-list-item class="my-0">
            <v-list-item-icon>
              <v-icon size="20">mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              moment(serverTime).format("DD-MM-YY hh:mm A")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      sm="8"
      md="2"
      class="my-2 my-sm-0 mr-auto"
      order="2"
      order-md="1"
    >
      <!-- selector de unidades -->
      <v-menu
        offset-y
        transition="scroll-y-transition"
        rounded="lg"
        style="background-color: inherit"
        v-if="selectedUnidad?.cargo"
        :disabled="unidades.length === 1"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            :id="`${unidades.length === 1 ? 'no-cursor' : ''}`"
            v-ripple="unidades.length > 1"
            v-bind="attrs"
            v-on="on"
            class="d-flex flex-row justify-center justify-sm-start align-center rounded-lg mx-2 pl-sm-6 py-1"
          >
            <div class="d-flex flex-column text-start text-none-style px-2">
              <span v-if="$vuetify.breakpoint.smAndUp" class="font-weight-bold">
                {{ cargoSelectedUnidad }}
              </span>
              <span
                v-if="
                  selectedUnidad?.cargo[0]?.id !== 10 &&
                  selectedUnidad?.cargo[0]?.id !== 16 &&
                  selectedUnidad?.cargo[0]?.id !== 8 &&
                  selectedUnidad?.cargo[0]?.id !== 1
                "
              >
                {{ selectedUnidad?.nombre }}
              </span>
              <span
                v-if="
                  selectedUnidad?.cargo[0]?.id !== 10 &&
                  selectedUnidad?.cargo[0]?.id !== 16 &&
                  selectedUnidad?.cargo[0]?.id !== 1 &&
                  $vuetify.breakpoint.smAndUp
                "
              >
                <b v-if="selectedUnidad?.instituciones?.codigo">
                  {{ selectedUnidad?.instituciones?.codigo }} -
                </b>
                {{ selectedUnidad?.instituciones?.nombre }}
              </span>
            </div>
            <v-icon class="pl-1" v-if="unidades.length > 1">
              mdi-chevron-down
            </v-icon>
          </div>
        </template>
        <v-sheet id="units-container" v-if="unidades.length > 1" class="py-2">
          <div v-for="(unidad, key) in unidades" :key="key">
            <div
              v-ripple
              id="unit-item"
              class="d-flex flex-column text-start text-none-style px-2 py-2"
              @click="changeUnidad(unidad)"
            >
              <span class="font-weight-bold">
                {{ unidad?.cargo[0]?.nombre }}
              </span>
              <span> {{ unidad?.nombre }} </span>
              <span> {{ unidad?.instituciones?.nombre }} </span>
            </div>
          </div>
        </v-sheet>
      </v-menu>
      <div v-else>
        <p class="ml-4" v-if="$vuetify.breakpoint.smAndUp">
          {{ moment(serverTime).format("DD-MM-YY hh:mm A") }}
        </p>
        <p v-else class="text-center m-0">
          {{ moment(serverTime).format("DD-MM-YY hh:mm:ss A") }}
        </p>
      </div>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="0"
      md="auto"
      order="0"
      order-md="3"
    >
      <v-img
        :src="require(`@/assets/img/goes_horizontal.png`)"
        width="200"
        contain
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
      md="auto"
      class="d-flex justify-center align-center ml-auto"
      order="3"
      order-md="3"
    >
      <!-- selector de año fiscal -->
      <v-menu
        offset-y
        transition="scroll-y-transition"
        rounded="lg"
        style="z-index: 300"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn class="mx-2" v-bind="attrs" v-on="on" text small>
            <b>AÑO FISCAL: {{ anioFiscal }}</b>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template> -->
        <!-- <v-list dense nav>
          <v-list-item v-for="(item, index) in anios" :key="index">
            <v-list-item-title
              @click="setAnioFiscal(item.anio)"
              style="cursor: pointer"
            >
              AÑO FISCAL: {{ item.anio }}
            </v-list-item-title>
          </v-list-item>
        </v-list> -->
      </v-menu>
    </v-col>
    <CambioContraComponent
      :showDialog="showPasswordDialog"
      @close="showPasswordDialog = false"
    />
    <AgregarTop :showDialog="showTopCreate" @close="showTopCreate = false" />
    <v-dialog
      v-model="modalRecordatorio"
      persistent
      max-width="700"
      min-height="700"
    >
      <v-card>
        <v-card-title> Recordatorio </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Por medidas de seguridad, debe configurar el inicio de sesión a
              través de authenticator
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="primary"
            @click="(showTopCreate = true), (modalRecordatorio = false)"
          >
            Configurar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SocketIO from "socket.io-client";
import { mapActions, mapMutations, mapState } from "vuex";
import moment from "moment-timezone";
// import {clearInterval as clearIntervalWorkers, setInterval as setIntervalWorkers} from "worker-timers";
import NotificacionesComponent from "./NotificacionesComponent.vue";
import CambioContraComponent from "./CambioContraComponent.vue";
import AgregarTop from "./AgregarTop.vue";
import { CartComponent } from "@/components/catalogoEnLinea/cart";
import { FeedbackModalComponent } from "@/components/utils";
import { Listener, Observable } from "@/utils/observable";
import { LocalStorage } from "@/utils/LocalStorage.mjs";

export default {
  name: "AppHeaderComponent",
  data: () => ({
    showPasswordDialog: false,
    interval: null,
    worker: new Worker("/time-worker.js"),
    inactividadWorker: new Worker("/inactividad-worker.js"),
    timeSyncing: {
      fetchedAt: null,
      serverTime: null,
    },
    tabFocus: true,
    // timeoutInterval: 10000,
    timeoutInterval: 14400000,
    unsubscriber: null,
    showTopCreate: false,
    modalRecordatorio: true,
  }),
  computed: {
    ...mapState([
      "userInfo",
      "anioFiscal",
      "unidades",
      "selectedUnidad",
      "notificaciones_list",
      "token",
      "sockets",
      "serverTime",
    ]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
    ...mapState("catalogoEnLinea", ["feedbackModal"]),
    cargoSelectedUnidad() {
      const esDelegado = this.selectedUnidad?.usuario?.es_delegado ?? false;
      let cargoNombre;
      if (this.selectedUnidad?.cargo?.length > 0) {
        if (esDelegado) {
          cargoNombre = `DELEGADO ${this.selectedUnidad?.cargo[0]?.nombre}`;
          return cargoNombre;
        } else {
          return this.selectedUnidad?.cargo[0]?.nombre;
        }
      } else {
        return "";
      }
    },
    hasAccessToCart() {
      return (
        this.haveRole("ROLE_CM_CATALOGO_ELECTRONICO_CARRITO") &&
        !this.haveRole("ROLE_SUPER_ADMIN")
      );
    },
    notificaciones_pendientes() {
      return this.notificaciones_list.filter((target) => !target.read).length;
    },
    anios() {
      // TODO: Generar consulta a bd para obtener años activos
      let list = [];
      const actual = parseInt(moment().format("Y"));
      for (let x = actual; x <= actual; x++) {
        list.push({ anio: x });
      }
      if (this.anioFiscal == null) {
        const local = localStorage.getItem("anioFiscalSeleccionado");
        this.$store.commit("setAnioFiscal", local !== null ? local : actual);
      }
      return list.reverse();
    },
    maximoTiempoInactividad() {
      // Milisegundos
      return 240 * 60 * 1000;
    }
  },
  methods: {
    async obtenerTiempoServidor(esPrimeraVez = false) {
      const { data } = await this.services.Auth.getServerTime();
      if (!data.time) return;
      this.setServerTime(moment(data.time, "x"));
      this.timeSyncing = {
        fetchedAt: Date.now(),
        serverTime: +data.time,
      };

      if (esPrimeraVez) {
        this.generateSetInterval();
      } else {
        this.timeSyncing = {
          fetchedAt: null,
          serverTime: null,
        };
        this.worker.postMessage("restart");
      }
    },
    async generateSetInterval() {
      this.worker.addEventListener("message", this.manejarEventoWorker);
      this.worker.postMessage("start");
    },
    manejarEventoWorker(evento) {
      const nombreEvento = evento.data.nombre;

      switch (nombreEvento) {
        case 'start':
          this.setServerTime(moment(this.serverTime).add(1, "second"));
          break;
        case 'stop':
          break;
        case 'restart':
          break;
        case 'tick': 
          this.delayTicks++;

          const time = this.serverTime;
          this.setServerTime(moment(time).add(1, "second"));
          break;
      }
    },
    validateSyncing() {
      if (
        document.hidden ||
        !(this.timeSyncing.fetchedAt && this.timeSyncing.serverTime)
      )
        return;
      const timeAgoFromFetching = Date.now() - this.timeSyncing.fetchedAt;
      const idealServerTime = moment(this.timeSyncing.serverTime).add(
        timeAgoFromFetching,
        "milliseconds"
      );
      const diff = idealServerTime.diff(this.serverTime, "seconds");

      if (diff !== 0) {
        this.worker.postMessage("stop");
        this.setServerTime(idealServerTime.clone());
        this.worker.postMessage("start");
      }
    },
    ...mapMutations("utils", ["setSideBar", "setMenu"]),
    ...mapMutations([
      "setToken",
      "setAnioFiscal",
      "setSelectedUnidad",
      "setUnidades",
      "setNotificaciones",
      "setSocket",
      "setServerTime",
      "setInstitucionesUsuario",
    ]),
    ...mapMutations("catalogoEnLinea", [
      "closeFeedbackModal",
      "clearInCartRequest",
    ]),
    ...mapMutations("cmSolicitudesConObservacion", ["clearRequestsToModify"]),
    ...mapActions("utils", ["toggleDrawer"]),
    async detectFocusOut() {
      let inView = false;
      const onWindowFocusChange = async (e) => {
        if ({ focus: 1, pageshow: 1 }[e.type]) {
          if (inView) return;
          this.tabFocus = true;
          inView = true;
          // await this.obtenerTiempoServidor();
          this.validateSyncing();
        } else if (inView) {
          this.tabFocus = !this.tabFocus;
          inView = false;
        }
      };
      //se agregan los eventos bajo los cuales se debe realizar el cambio de estado para el tabFocus
      window.addEventListener("focus", onWindowFocusChange);
      window.addEventListener("blur", onWindowFocusChange);
      window.addEventListener("pageshow", onWindowFocusChange);
      window.addEventListener("pagehide", onWindowFocusChange);
    },
    async cerrarSession() {
      try {
        this.setMenu([]);
        this.setToken(null);
        this.setSelectedUnidad({});
        this.setUnidades([]);
        this.clearInCartRequest();
        this.clearRequestsToModify();
        this.setInstitucionesUsuario([]);
        const tokenID = localStorage.getItem("tokenID");

        if (tokenID) {
          await this.services.Auth.logout(tokenID);
        }
      } finally {
        localStorage.clear();
        Observable.emit("logout");
        this.$router.push({ name: "identidad-digital" }).catch((e) => {});
      }
    },
    init() {
      this.iniciarTimerInactividad();
      this.iniciarDetectorInactividad();
    },
    handleFeedbackClose() {
      this.feedbackModal.onClose?.();
      this.closeFeedbackModal();
    },
    changeUnidad(unidad) {
      if (this.selectedUnidad?.id === unidad?.id) return;
      localStorage.setItem("idUnidad", unidad.id);
      this.setSelectedUnidad(unidad);
      this.$router.push({ name: "dashboard" }).catch((e) => {});
    },
    async getNotificaciones() {
      const { data } = await this.services.Notificaciones.getNotificaciones();
      this.setNotificaciones(data);
    },

    // INACTIVIDAD DE USUARIO
    manejarEventoWorkerInactividad(evento) {
      const nombreEvento = evento.data.nombre;
      switch (nombreEvento) {
        case 'scheduled':
          const horaCierre = moment().add(evento.data.payload, 'milliseconds');

          // console.log(`Se cerrara su sesion por inactividad: ${horaCierre.format("DD/MM/YYYY HH:mm:ss.SSS")}`);
          break;
        case 'inactivity-timeout':
          const ultimaActualizacion = moment(+LocalStorage.fecha_ultima_accion);

          if (ultimaActualizacion.isValid()) {
            const diferencia = moment().diff(ultimaActualizacion, 'milliseconds');
            const tiempoRestante = this.maximoTiempoInactividad - diferencia;

            if (tiempoRestante > 0) {
              this.inactividadWorker.postMessage({ name: "clear" });
              this.inactividadWorker.postMessage({ name: "schedule", payload: tiempoRestante });
              return;
            }
          }

          this.cerrarSession();
          this.pushAppMessage({
            message: "Su sesión ha sido cerrada por inactividad. Por favor, vuelva a iniciar sesión",
            type: "info",
            timeout: 10000,
            id: `logoff-${this.timeoutInterval}`,
          });

          break;
        case 'cleared':
          break;
      }
    },
    iniciarTimerInactividad() {
      LocalStorage.fecha_ultima_accion = moment().format('x');
      this.inactividadWorker.addEventListener("message", this.manejarEventoWorkerInactividad);
      this.inactividadWorker.postMessage({ name: "schedule", payload: this.maximoTiempoInactividad });
    },
    detenerTimerInactividad() {
      this.inactividadWorker.postMessage({ name: "clear" });
    },
    reiniciarTimerInactividad() {
      LocalStorage.fecha_ultima_accion = moment().format('x');
      this.inactividadWorker.postMessage({ name: "clear" });
      this.inactividadWorker.postMessage({ name: "schedule", payload: this.maximoTiempoInactividad });
    },
    iniciarDetectorInactividad() {
      document.addEventListener("click", this.reiniciarTimerInactividad);
    },
    removerDetectorInactividad() {
      document.removeEventListener("click", this.reiniciarTimerInactividad);
      this.detenerTimerInactividad();
      this.inactividadWorker.removeEventListener("message", this.manejarEventoWorkerInactividad);
      LocalStorage.fecha_ultima_accion = null;
    },
  },
  components: {
    NotificacionesComponent,
    CambioContraComponent,
    CartComponent,
    FeedbackModalComponent,
    AgregarTop,
  },
  async created() {
    const noDebeNotificar =
      this.userInfo.user.metodo.id === 3 || this.userInfo.user.skip2fa;

    if (noDebeNotificar) {
      this.modalRecordatorio = false;
    }

    await this.getNotificaciones();
    await this.detectFocusOut();

    const listener = new Listener("AppHeaderComponentHoraServidor").setHandler(
      () => this.obtenerTiempoServidor()
    );
    const unsubscriber = Observable.getInstance().subscribe(
      "REFRESCAR-HORA-SERVIDOR",
      listener
    );

    this.unsubscriber = unsubscriber;
  },
  beforeMount() {
    this.obtenerTiempoServidor(true);
  },
  mounted() {
    this.init();
    document.addEventListener("visibilitychange", this.validateSyncing);
    if (!this.sockets.notificaciones) {
      this.setSocket({
        socket: "notificaciones",
        connection: SocketIO(`${process.env.VUE_APP_SOCKET_URL}`, {
          auth: { token: this.token || localStorage.getItem("token") },
          reconnection: {
            attempts: 20,
            delay: 1000,
            onReconnectError: async (error) => {
              // const { status, mensaje } = await AuthServices.almacenarErrorConexion({
              //   error: error.message,
              // });
              console.log("error", error);
            },
          },
        }),
      });

      this.sockets.notificaciones.on("connect", () => {
        this.sockets.notificaciones.emit("join", {
          id_usuario: this.userInfo.user.id,
        });
        this.sockets.notificaciones.on("notificaciones", async (data) => {
          if (
            data.contenido.includes(
              "Haga click aquí para descargar la orden de compra"
            )
          ) {
            const [, codigoOrdenCompra] = data.contenido.split(": ");
            const response =
              await this.services.PurchaseRequest.obtenerOrdenCompraCatalogo({
                codigo_orden: codigoOrdenCompra,
              });
            const blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            const file = new File([blob], "orden-compra", {
              type: response.headers["content-type"],
            });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(file);
            link.download = file.name;
            link.click();
          }
          this.getNotificaciones();
        });

        this.sockets.notificaciones.on("CERRAR_SESSION_MASIVO", () => {
          this.cerrarSession();
        });
      });

      this.sockets.notificaciones.nsp = `/notificaciones`;
      this.sockets.notificaciones.connect();
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  beforeDestroy() {
    this.sockets.notificaciones.disconnect();
    this.setSocket({ socket: "notificaciones", connection: null });
    document.removeEventListener("visibilitychange", () => null);
    this.worker.removeEventListener("message", this.manejarEventoWorker);

    this.unsubscriber?.();
    this.removerDetectorInactividad();
  },
};
</script>
<style lang="scss" scoped>
.text-none-style {
  text-transform: none;
  font-size: 10px !important;
}

#no-cursor {
  cursor: default;
}

#units-container {
  cursor: pointer;
}

#unit-item:hover {
  background-color: #e0e0e0;
}

:deep(div .v-banner__wrapper) {
  padding: 0 !important;
}

@media (min-width: 900px) {
  .v-menu__content {
    top: 90px !important;
  }
}

@media (max-width: 900px) {
  .v-menu__content {
    top: 130px !important;
  }
}
</style>
