import Vue from "vue";

// importaciones de archivos
import AgreementDocumentType from "./ConvenioTipoDocumento.services";
import AgreementMarco from "./ConvenioMarco.services";
import Auth from "./Auth.services";
import Asuetos from "./Asuetos.services";
import RecursoRevision from "./RecursoRevision.services";
import RecursoApelacion from "./RecursoApelacion.services";

import BusinessActivities from "./GiroEmpresarial.services";
import ClasificacionEmpresa from "./ClasificacionEmpresa.services";
import Contactos from "./Contactos.services";
import Contribuyentes from "./Contribuyentes.services";
import Departamentos from "./Departamentos.services";
import Documentos from "./Documentos.services";
import Employee from "./Empleados.services";
import Generos from "./Generos.services";
import IdentificadorFiscal from "./IdentificadorFiscal.services";
import Municipios from "./Municipios.services";
import Obs from "./Obs.services";
import ObsType from "./TiposObs.services";
import Paises from "./Paises.services";
import Path from "./Path.services";
import Phases from "./Fases.services";
import Presentations from "./Presentaciones.services";
import Proveedores from "./Proveedores.services";
import ProveedoresProcuctos from "./ProveedoresProductos.services";
import SolicitudProveedor from "./SolicitudProveedor.services";
import VerificarCorreo from "./VerificarCorreo.services";
import VerificarDocumento from "./VerificarDocumento.services";
import UnidadesServices from "./Unidades.services";
import Usuarios from "./Usuarios.services";
import Instituciones from "./Instituciones.services";
import Indicadores from "./Indicadores.services";
import SolicitudesUsuarios from "./SolicitudesUsuarios.services";
import EstructurasPresupuestarias from "./EstructurasPresupuestarias.services";
import LineasTrabajos from "./LineasTrabajos.services";
import FuentesFinanciamientos from "./FuentesFinanciamientos.services";
import Organismos from "./Organismos.services";
import Convenios from "./Convenios.services";
import Financiamientos from "./Financiamientos.services";
import SolicitudCompra from "./SolicitudCompra.services";
import Paac from "./Paac.services";
import ContratoService from "./Contrato.services";
import Reporteria from "./Reporteria.services";
import Notificaciones from "./Notificaciones.services";
import Sanciones from "./Sanciones.services";
import CifradosPresupuestarios from "./CifradosPresupuestarios.services";
import Contralores from "./Contralores.services";
import PacServices from "./Pac.services";
import PeoServices from "./Peo.services";
import Tarjetas from "./Tarjetas.services";

import Grupos from "./Grupos.services";
import Cifrados from "./Cifrados.services";
import TourService from "./TourGuiado.services";
import PacProcesos from "./PacProcesos.services";
import PacProcesosV1 from "./PacProcesosV1.services";
import RegistroPublico from "./RegistroPublico.services";

import BajaCuantia from "./BajaCuantia.services";
import ProveedorEvaluacion from "./ProveedorEvaluacion.services";
import ProcesoReserva from "./ProcesoReserva.services";
import PrevencionesServices from "./Prevenciones.services";
import CompraLinea from "./SolicitudCompraLinea.services";
import CompraPorEmergencia from "./CompraPorEmergencia.services";
import CertificacionFondo from "./CertificacionFondo.services";
import TipoHito from "./TipoHito.services";
import canalesDeComunicacion from "./CanalesDeComunicacion.services";

// Catalogo en linea
import Family from "./catalogoEnLinea/Family.services";
import Group from "./catalogoEnLinea/Group.services";
import SubGroup from "./catalogoEnLinea/SubGroup.services";
import Product from "./catalogoEnLinea/Product.service";
import Region from "./catalogoEnLinea/Region.services";
import PurchaseRequest from "./catalogoEnLinea/PurchaseRequest.services";
import CmFamilias from "./CmFamilias.services";
import CmGrupos from "./CmGrupos.services";
import CmSubgrupos from "./CmSubgrupos.services";
import CmReporteria from "./catalogoEnLinea/cmReporteria.services";

import cmRegiones from "./cmRegiones.services";
import cmAtributos from "./cmAtributos.services";
import cmCategoriaAtributos from "./cmCategoriaAtributos.services";
import cmProducto from "./cmProducto.services";
import cmTipoconvenio from "./CmTipoConvenio.services";
import cmConvenioMarco from "./CmConvenioMarco.services";
import cmSupplierApproval from "./catalogoEnLinea/SupplierApproval.services";
import cmContactosProveedorServices from "./cmContactosProveedor.services";
import cmOrdenesCompra from "./cmOrdenesCompra.services";
import CmOfertaServices from "./CmOferta.services";

import cmAprobacionSolicitudCompra from "./AprobacionSolicitudCompraElectronica";
import CMSolicitudesConObservacion from "./CMSolicitudesConObservacion.services";
import ConvocatoriaServices from "./Convocatoria.services";
import compraEnLineaServices from "./compraEnLinea.services";
import DocumentosAniosFiscalServices from "./DocumentosAniosFiscal.services";
import SeguimientoProcesosServices from "./SeguimientoProcesos.services";

import compraEnExterior from "./CompraExterior.services";
import VerificacionDocumentosOdcServices from "./VerificacionDocumentosOdc.services";
import SeguimientoContractual from "./SeguimientoContractual.services";

import edicionContrato from "./EdicionContrato.services";

import DelegadosUCp from "./DelegadosUcp.services";

import reasignacionContractual from "./reasignacionContractual.services";

import ComprasCentroEscolarServices from "./ComprasCentroEscolar.services.mjs";

import InteligenciaMercadoServices from "./InteligenciaMercado.services";
import RefinanciamientoCompras from "./RefinanciamientoCompras.services";
import CertificacionRefinanciamientoCompras from "./CertificacionRefinanciamientoCompras.services";

import PrevencionRecursoRevisionServices from "./PrevencionRecursoRevision.services";

import ProcesosPublicos from "./ProcesosPublicos.services";
import LoginListadoProveedoresServices from "./LoginListadoProveedores.services";

import Autenticacion from "./Autenticacion.services.js";

import administracionCorreoServices from "./administracionCorreo.services.mjs";

import ModificativasProcesosServices from "./ModificativasProcesos.services.mjs";
import reiniciosSuibastaServices from "./reiniciosSuibasta.services.mjs";

import envioDocumentosProveedor from "./envioDocumentos.services.mjs";

import AdjudicacionServices from "./Adjudicacion.services.mjs";
import SolicitudesNecesidad from "./SolicitudesNecesidad.services";

import EmpleadosProveedor from "./EmpleadosProveedor.mjs";

import reporteriaBajaCuantiaServices from "./reporteriaBajaCuantia.services";
import ProcesosLibresServices from "./ProcesosLibres.services.mjs";

import UsuariosServicesV2 from "./Usuarios.services.v2";
import EliminarEtapasContratoServices from "./EliminarEtapasContrato.services.mjs";

import cambioEstadoProcesoServices from "./cambioEstadoProceso.services";

import reporteriaAutoriaInterna from "./reporteriaAutoriaInterna.services";

import ReporteriaModalidadesCompraServices from "./ReporteriaModalidadesCompra.services";

import CmHerramienta from "./CmHerramienta.services";

import reporteriaCompraEnLineaServices from "./reporteriaCompraEnLinea.mjs";

import permisosExtraServices from "./permisosExtraServices.mjs";

import EliminarEspecificasProcesoServices from "./EliminarEspecificasProceso.services.mjs";

import reporteriaCierreContratosInstitucionServices from "./reporteriaCierreContratosInstitucion.services";
import AdministrarContratoServices from "./AdministrarContrato.services";

import reporteriaPlanificacionVsContratacionServices from "./reporteriaPlanificacionVsContratacion.services";

import RegistroCiudadanos from "./RegistroCiudadanos.services";

import HerramientaRecursoRevisionServices from "./HerramientaRecursoRevision.services";

import ProveedorNoRegistradoServices from "@/services/ProveedorNoRegistrado.services";

import ReporteriaAutorizacionPacServices from "./reporteriaAutorizacionPac.services";
import ReporteriaUCPServices from "./ReporteriaUCP.services.mjs";
import resumenServices from "./resumen.services.mjs";

import reporteriaCentrosEscolares from "./reporteriaCentrosEscolares.mjs";

import ProveedoresServices from "./Proveedores.services";

import ActividadesServices from "./Actividad.services";

import ReportePlanificacionPacServices from "./ReportePlanificacionPac.services";

Vue.prototype.services = {
  Asuetos,
  AgreementMarco,
  Auth,
  BusinessActivities,
  ClasificacionEmpresa,
  Contactos,
  Contribuyentes,
  Departamentos,
  Documentos,
  Employee,
  Generos,
  AgreementDocumentType,
  IdentificadorFiscal,
  Municipios,
  Obs,
  ObsType,
  Paises,
  Path,
  Phases,
  Presentations,
  Proveedores,
  ProveedoresProcuctos,
  SolicitudProveedor,
  VerificarCorreo,
  VerificarDocumento,
  UnidadesServices,
  Usuarios,
  Instituciones,
  SolicitudesUsuarios,
  EstructurasPresupuestarias,
  LineasTrabajos,
  FuentesFinanciamientos,
  Organismos,
  Indicadores,
  Convenios,
  Financiamientos,
  Grupos,
  SolicitudCompra,
  Paac,
  ContratoService,
  Reporteria,
  Notificaciones,
  Sanciones,
  Cifrados,
  CifradosPresupuestarios,
  TourService,
  Contralores,
  PacProcesos,
  PacServices,
  PeoServices,
  RegistroPublico,
  BajaCuantia,
  Tarjetas,
  ProveedorEvaluacion,
  ProcesoReserva,
  PrevencionesServices,
  CompraLinea,
  CompraPorEmergencia,
  CertificacionFondo,
  PacProcesosV1,
  TipoHito,
  Family,
  Group,
  SubGroup,
  Product,
  Region,
  PurchaseRequest,
  CmFamilias,
  CmGrupos,
  CmSubgrupos,
  cmRegiones,
  cmAtributos,
  cmCategoriaAtributos,
  cmProducto,
  cmTipoconvenio,
  cmConvenioMarco,
  cmAprobacionSolicitudCompra,
  cmSupplierApproval,
  CMSolicitudesConObservacion,
  RecursoRevision,
  RecursoApelacion,
  ConvocatoriaServices,
  canalesDeComunicacion,
  CompraEnLinea: compraEnLineaServices,
  DocumentosAniosFiscalServices,
  SeguimientoProcesos: SeguimientoProcesosServices,
  CmReporteria,
  cmOrdenesCompra,
  VerificacionDocumentosOdc: VerificacionDocumentosOdcServices,
  SeguimientoContractual,
  compraEnExterior,
  CmContactosProveedor: cmContactosProveedorServices,
  edicionContrato,
  DelegadosUCp,
  reasignacionContractual,
  ComprasCentroEscolar: ComprasCentroEscolarServices,
  inteligenciaMercado: InteligenciaMercadoServices,
  RefinanciamientoCompras,
  CertificacionRefinanciamientoCompras,
  CmOfertaServices,
  PrevencionRecursoRevision: PrevencionRecursoRevisionServices,
  ProcesosPublicos,
  LoginListadoProveedoresServices,
  Autenticacion,
  administracionCorreoServices,
  ModificativasProcesosServices,
  reiniciosSuibastaServices,
  envioDocumentosProveedor,
  AdjudicacionServices,
  SolicitudesNecesidad,
  EmpleadosProveedor,
  ReporteriaBajaCuantia: reporteriaBajaCuantiaServices,
  ProcesosLibresServices,
  EliminarEtapasContratoServices,
  UsuariosServicesV2,
  cambioEstadoProcesoServices,
  reporteriaAutoriaInterna,
  ReporteriaModalidadesCompra: ReporteriaModalidadesCompraServices,
  CmHerramienta,
  ReporteriaCompraEnLinea: reporteriaCompraEnLineaServices,
  permisosExtraServices,
  EliminarEspecificasProcesoServices,
  ReporteriaCierreContratosInstitucion: reporteriaCierreContratosInstitucionServices,
  AdministrarContratoServices,
  ReporteriaPlanificacionVsContratacion: reporteriaPlanificacionVsContratacionServices,
  RegistroCiudadanos,
  HerramientaRecursoRevision: HerramientaRecursoRevisionServices,
  ProveedorNoRegistradoServices,
  ReporteriaAutorizacionPAC: ReporteriaAutorizacionPacServices,
  ReporteriaUCP: ReporteriaUCPServices,
  ActividadesServices,
  ProveedoresServices,
  Resumen: resumenServices,
  reporteriaCentrosEscolares,
  ReportePlanificacionPac: ReportePlanificacionPacServices,
};
