import { http_client, http_client_with_status } from "@/plugins/http_client";

const cargarProveedores = async (params) => http_client_with_status('api/v1/proveedores/index/sencillo', params, 'get');

const getProviderInfo = async (id_proveedor, params = {}) => {
  if (id_proveedor) {
    return await http_client(
      `/api/v1/proveedores/${id_proveedor}`,
      params,
      "get"
    );
  }
  return await http_client(`/api/v1/proveedores/`, params, "get");
};

const getSolicitudesRegistro = async (id, params) => {
  return await http_client(
    `/api/v1/proveedores/solicitudes/registros/${id}`,
    params,
    "get"
  );
}

const putProviderLogo = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/upload/logo/${id}`,
    params,
    "put"
  );
};

const getProviderLogo = async (id, params = {}) => {
  try {
    return await http_client(
      `/api/v1/proveedores/logo/${id}`,
      params,
      "get",
      {},
      true,
      "blob",
      false
    );
  } catch (error) {}
};

const updateProviderInfo = async (params = {}) => {
  return await http_client("/api/v1/proveedores", params, "put");
};

const postProviderDocument = async (params = {}) => {
  return await http_client("/api/v1/proveedores/documentos", params, "post");
};

const putProviderDocument = async (id, params = {}, verificacion = '') => {
  return await http_client(
    `/api/v1/proveedores/documentos/${id}/${verificacion}`,
    params,
    "put"
  );
};

const getProviderDocumentRequired = async (params = {}, id_proveedor) => {
  let url = id_proveedor
    ? `/api/v1/documentos/requeridos/${id_proveedor}`
    : "/api/v1/documentos/requeridos";

  return await http_client(url, params, "get");
};

const postBusinessActivities = async (params = {}) => {
  return await http_client("/api/v1/proveedores/giros", params, "post");
};

const getBusinessActivitiesList = async (params = {}) => {
  return await http_client("/api/v1/proveedores/giros", params, "get");
};

const deleteBusinessActivities = async (id, params = {}) => {
  return await http_client(`/api/v1/proveedores/giros/${id}`, params, "delete");
};

/**
 * Funcion para guardar un accionista por medio de la api
 * @param {*} params datos del accionista
 * @returns
 */
const postShareholder = async (params = {}) => {
  try {
    return await http_client("/api/v1/proveedores/accionistas", params, "post");
  } catch (error) {
    return error.response;
  }
};

/**
 * Funcion para obtener la lista de accionistas por medio de la api
 */
const getShareholderList = async (params = {}) => {
  return await http_client("/api/v1/proveedores/accionistas", params, "get");
};

/**
 * Funcion para eliminar un accionista por medio de la api
 */
const deleteShareholder = async (id) => {
  return await http_client(
    `/api/v1/proveedores/accionistas/${id}`,
    {},
    "delete"
  );
};

const getObs = async (params = {}) => {
  return await http_client("/api/v1/obs", params, "get");
};

const postObs = async (params) => {
  return await http_client("", params, "post");
};

const getProviderAgreement = async (params = {}) => {
  return await http_client(`/api/v1/proveedores/convenios`, params, "get");
};

const getProviderAgreementTags = async (params = {}) => {
  return await http_client(
    `/api/v1/proveedores/convenios/obs/etiqueta`,
    params,
    "get"
  );
};

const getProviderAgreementObsFilter = async (params = {}) => {
  return await http_client(`/api/v1/proveedores/convenios/obs`, params, "get");
};

const getProviderContacts = async (params = {}) => {
  return await http_client("/api/v1/proveedores/contactos", params, "get");
};
const getProviderContact = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/contactos/${id}`,
    params,
    "get"
  );
};

const postProviderContacts = async (params = {}) => {
  return await http_client("/api/v1/proveedores/contactos", params, "post");
};

const putProviderContacts = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/contactos/${id}`,
    params,
    "put"
  );
};

const deleteProviderContacts = async (id, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/contactos/${id}`,
    params,
    "delete"
  );
};

/**
 * Función para eliminar un OBS asociado a un contacto de un accionista
 */
const deleteObsContact = async (id_obs, id_contact, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/obs/${id_obs}/contacto/${id_contact}`,
    params,
    "delete"
  );
};

const postProviderOBSPresentation = async (id, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/${id}/presentaciones`,
    params,
    "post"
  );
};

const deleteProviderOBSPresentation = async (id, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/presentaciones/${id}`,
    params,
    "delete"
  );
};

const putProviderOBSPresentation = async (id, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/presentaciones/${id}`,
    params,
    "put"
  );
};

const getProviderOBSPresentation = async (id, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/${id}/presentaciones`,
    params,
    "get"
  );
};

const putProviderOBS = async (id, params = {}) => {
  return await http_client(`/api/v1/obs/proveedores/${id}`, params, "put");
};

const getProviderOBSPresentationDetail = async (id, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/presentaciones/${id}`,
    params,
    "get"
  );
};

const deleteProviderOBSPresentationPhoto = async (id, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/presentaciones/fotografia/${id}`,
    params,
    "delete"
  );
};

const downloadExcelDocumentFormat = async (params = {}) => {
  return await http_client(
    `/api/v1/documentos/excel`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const cargarNominaAccionistas = async (params) => {
  try {
    const response = await http_client(
      `/api/v1/proveedores/accionistas/nomina`,
      params,
      "post"
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

const cargarNominaLegal = async (params) => {
  try {
    const response = await http_client(
      `/api/v1/proveedores/accionistas/nomina/pdf`,
      params,
      "post"
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

const obtenerNominaLegal = async (params) => {
  try {
    const response = await http_client(
      `/api/v1/proveedores/accionistas/nomina/pdf`,
      params,
      "get",
      {},
      true,
      "",
      false
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

const cargarAccionista = async (idAccionista) => http_client(`/api/v1/proveedores/accionistas/detalle/${idAccionista}`, null, 'get');

const getProviderPercentage = async (id_proveedor = null, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/informacion/estado/${id_proveedor ?? ""}`,
    params,
    "get"
  );
};

const getDocumentoProveedor = async (params = {}) => {
  return await http_client(
    "/api/v1/proveedores/documentos/pdf",
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

//PROVEEDOR PAAC
const getProcesosProveedores = async (params = {}) => {
  return await http_client("/api/v1/procesos/proveedores", params, "get");
};
const getProcesosProveedoresConjuntos = async (params = {}) => {
  return await http_client("/api/v1/procesos/proveedores/conjuntos", params, "get");
};

const getTipoEntidades = async () => {
  return await http_client("/api/v1/tipo-entidad", {}, "get");
};

const getTipoContrato = async () => {
  return await http_client(
    "/api/v1/forma/contratacion/tipo-contrato",
    {},
    "get"
  );
};

const getOfertasPaac = async (procesoCompraId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/proveedores/procesos/ofertas/${procesoCompraId}`,
    params,
    "get"
  );
};

const getEspecificacionObs = async (
  procesoCompraId,
  procesoObsId,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/proveedores/procesos/ofertas/${procesoCompraId}/${procesoObsId}`,
    params,
    "get"
  );
};

const getDetalleSubProcesoPaac = async (
  procesoCompraId,
  procesoObsId,
  subprocesoObsId
) => {
  return await http_client(
    `/api/v1/procesos/proveedores/procesos/ofertas/${procesoCompraId}/${procesoObsId}/${subprocesoObsId}`,
    {},
    "get"
  );
};

const getDetalleSubProcesoPaacOferta = async (
  procesoCompraId,
  procesoObsId,
  subprocesoObsId
) => {
  return await http_client(
    `/api/v1/procesos/proveedores/procesos/ofertas/${procesoCompraId}/${procesoObsId}/${subprocesoObsId}/oferta`,
    {},
    "get"
  );
};

const postOfertaPaac = async (
  procesoCompraId,
  procesoObsId,
  subprocesoObsId,
  payload
) => {
  return await http_client(
    `/api/v1/procesos/proveedores/procesos/ofertas/${procesoCompraId}/${procesoObsId}/${subprocesoObsId}`,
    payload,
    "post"
  );
};

const putOfertaPaac = async (
  procesoCompraId,
  procesoObsId,
  subprocesoObsId,
  proveedorParticipanteId,
  payload
) => {
  return await http_client(
    `/api/v1/procesos/proveedores/procesos/ofertas/${procesoCompraId}/${procesoObsId}/${subprocesoObsId}/${proveedorParticipanteId}`,
    payload,
    "put"
  );
};

const getEvaluaciones = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/etapas/actual`,
    params,
    "get"
  );
};

const getEvaluacionDetalle = async (
  id_proceso_compra,
  id_subproceso_obs,
  id_proveedor
) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/apertura/${id_subproceso_obs}?id_proveedor=${id_proveedor}`
  );
};

const getObsProveedor = async (id_proceso_compra) => {
  return await http_client(`/api/v1/procesos/compra/${id_proceso_compra}/obs`);
};

const validarParticipacionProveedor = async (id_proceso_compra) => {
  return await http_client(
    `api/v1/procesos/${id_proceso_compra}/verificar-participacion`
  );
};

const getCalificacionProveedor = async (id_proveedor) =>
  await http_client(`/api/v1/proveedores/${id_proveedor}/calificaciones`, {});

const getProveedores = async (params = {}) => {
  return await http_client("/api/v1/proveedores/listar", params, "get");
};

const getProveedoresRupes = async (params = {}) => {
  return await http_client("/api/v1/proveedores/listar/rupes", params, "get");
};

const getProveedoresReporte = (filters = {}) => http_client(
  "/api/v1/proveedores/listar/rupes/reporte",
  filters,
  "get",
  {},
  true,
  "blob"
);

const getProcesosProveedor = async (id_proveedor, params = {}) =>
  await http_client(`/api/v1/proveedores/${id_proveedor}/procesos`, params);

const sancionActivaProveedor = async (id_proveedor) =>
  await http_client(`/api/v1/proveedores/sanciones/bloqueo`);

const getContratosProveedor = async (id_proveedor, params = {}) =>
  await http_client(`/api/v1/proveedores/${id_proveedor}/contratos`, params);

const getSancionProveedor = async (id_proveedor) =>
  await http_client(`/api/v1/proveedores/${id_proveedor}/sanciones`);

const addAlertProveedor = async (payload) =>
  await http_client(`/api/v1/proveedores/alerta`, payload, "put");

const sendTemporalPassword = async (id_usuario) =>
  await http_client(`api/v1/proveedores/${id_usuario}/password`, {}, "put");

const getReport = async () =>
  await http_client(
    "/api/v1/proveedores/registrados/reporte",
    {},
    "get",
    { responseType: "blob" },
    true,
    "blob",
    false
  );

const verificarProveedor = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/verificacion/${id_proveedor}`,
    params,
    "put"
  );
};

const revisionProveedor = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/solicitar/verificacion/${id_proveedor}`,
    params,
    "put"
  );
};

const getObsPresentaciones = async (id_proveedor, params = {}) => {
  return await http_client(
    `/api/v1/obs/proveedores/${id_proveedor}/list`,
    params,
    "get"
  );
};

const getCatalogoInstituciones = async (params = {}) => {
  return await http_client(`/api/v1/proveedores/catalogo/instituciones`, params, "get");
};

const descargarReportePac = async (id_institucion, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/pac/institucion/${id_institucion}/externo`,
    params,
    "get",
    {},
    true,
    "blob"
  );
};

const verificarValidacionDocs = async () => await http_client(`api/v1/proveedores/validar-revision`, {});
const getDocsProveedor = async () => await http_client(`api/v1/proveedores/listar/documentos`, {});
const confirmReview = async () => await http_client(`api/v1/proveedores/confirmar-revision`, {});
const getPorcentajeProveedor = async () => await http_client_with_status(`api/v1/proveedores/cargar/proveedor/porcentaje`, {});

const generarReporteProveedor = async (params = {}) =>
  await http_client(`/api/v1/proveedores/cron-job/reporte/proveedor?now=true`, params, "post");

const generarReporteParticipacion = async (id_proveedor, params = {}) =>
await http_client(`/api/v1/proveedores/cron-job/reporte/participacion/${id_proveedor}?now=true`, params, "post");

const deleteDocumentoApoderado = async (id_documento, params = {}) => {
  return await http_client(
    `/api/v1/proveedores/documentos/eliminar-apoderado/${id_documento}`,
    params,
    "delete"
  );
};

const existeActividadProveedor = async(params = {}) => 
  await http_client('api/v1/proveedores/verificacion/actividades-proveedor', params, 'get');


export default {
  getProveedoresRupes,
  getProveedores,
  getProviderInfo,
  putProviderLogo,
  getProviderLogo,
  postProviderDocument,
  getProviderDocumentRequired,
  postBusinessActivities,
  getBusinessActivitiesList,
  deleteBusinessActivities,
  postShareholder,
  getShareholderList,
  deleteShareholder,
  getObs,
  postObs,
  getProviderAgreement,
  getProviderAgreementTags,
  getProviderAgreementObsFilter,
  postProviderContacts,
  getProviderContacts,
  putProviderContacts,
  deleteProviderContacts,
  getProviderContact,
  deleteObsContact,
  postProviderOBSPresentation,
  deleteProviderOBSPresentation,
  putProviderOBSPresentation,
  getProviderOBSPresentation,
  getProviderOBSPresentationDetail,
  updateProviderInfo,
  deleteProviderOBSPresentationPhoto,
  downloadExcelDocumentFormat,
  cargarNominaAccionistas,
  getProviderPercentage,
  cargarNominaLegal,
  obtenerNominaLegal,
  putProviderDocument,
  getDocumentoProveedor,
  getProcesosProveedores,
  getProcesosProveedoresConjuntos,
  getTipoEntidades,
  getTipoContrato,
  putProviderOBS,

  //PaaC proveedor
  getOfertasPaac,
  getEspecificacionObs,
  getDetalleSubProcesoPaac,
  getDetalleSubProcesoPaacOferta,
  postOfertaPaac,
  putOfertaPaac,
  getEvaluaciones,
  getEvaluacionDetalle,
  getObsProveedor,
  validarParticipacionProveedor,
  getCalificacionProveedor,

  getProcesosProveedor,
  sancionActivaProveedor,
  getContratosProveedor,
  getSancionProveedor,

  addAlertProveedor,
  sendTemporalPassword,
  getReport,
  verificarProveedor,
  revisionProveedor,
  getObsPresentaciones,
  verificarValidacionDocs,
  getDocsProveedor,
  confirmReview,
  getProveedoresReporte,
  getPorcentajeProveedor,
  generarReporteProveedor,
  cargarAccionista,
  getCatalogoInstituciones,
  descargarReportePac,
  generarReporteParticipacion,
  cargarProveedores,
  deleteDocumentoApoderado,
  getSolicitudesRegistro,
  existeActividadProveedor
};
