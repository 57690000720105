<template>
  <div>
    <div class="rich-text-editor">
      <tiptap-vuetify
        ref="editor"
        @input="handleContentChange"
        :placeholder="placeholder"
        :extensions="extensions"
        :value="value"
      />
      <input type="file" @change="uploadImage" accept="image/*" />
    </div>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
} from "tiptap-vuetify";

export default {
  name: "AppRichTextComponent",
  props: {
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, { options: { levels: [1, 2, 3] } }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      Image,
    ],
  }),
  methods: {
    handleContentChange(value) {
      this.$emit("input", value === "<p></p>" ? null : value);
    },

    async uploadImage(event) {
      const file = event.target.files[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await this.services.Notificaciones.almacenarImagen(
        formData
      );
      this.$refs.editor.editor.chain().focus().setImage({ src: data }).run();
    },
  },
};
</script>

<style>
.ProseMirror {
  min-height: 200px;
}
</style>
