import { http_client, http_client_with_status } from "@/plugins/http_client";

const getTecnicosProcesoCompra = async (id_proceso_compra) => {
  return await http_client(`/api/v1/procesos/${id_proceso_compra}/tecnicos`);
};

const getInfoGeneralProcesoCompra = async (id_proceso_compra) => {
  return await http_client(`/api/v1/procesos/${id_proceso_compra}`);
};

const putInforGeneralProcesoCompra = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}`,
    params,
    "put"
  );
};

const getListaDocumentosProcesoCompra = async (params) => {
  return await http_client(`/api/v1/procesos/documentos/tipos`, params);
};

const getDocumentosProcesoCompra = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/documentos`,
    params,
    "get"
  );
};

const postDocumentosProcesoCompra = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/documentos`,
    params,
    "post"
  );
};

const deleteDocumentoProcesoCompra = async (id_documento_proceso) => {
  return await http_client(
    `/api/v1/procesos/documentos/${id_documento_proceso}`,
    {},
    "delete"
  );
};

const downloadDocumentoProcesoCompra = async (params) => {
  return await http_client(
    `/api/v1/documentos?documento=${params}`,
    {},
    "get",
    {},
    true,
    "blob"
  );
};

const getEtapasProcesoCompra = async (id_proceso_compra) => {
  return await http_client(`/api/v1/procesos/${id_proceso_compra}/etapas`);
};

const putEtapasProcesoCompra = async (id_etapa_proceso, params) => {
  try {
    return await http_client(
      `/api/v1/procesos/etapas/${id_etapa_proceso}`,
      params,
      "put"
    );
  } catch (error) {
    return error.response;
  }
};

const postDocumentoEtapasProcesoCompra = async (id_etapa_proceso, params) => {
  return await http_client(
    `/api/v1/procesos/etapas/${id_etapa_proceso}/documentos`,
    params,
    "post"
  );
};
const postDocumentoEtapasProcesoCompraProveedor = async (
  id_etapa_proceso,
  params
) => {
  return await http_client(
    `/api/v1/procesos/${id_etapa_proceso}/etapas/actual/archivo/recursos-revision`,
    params,
    "post"
  );
};

const participar = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/participaciones`,
    {},
    "post"
  );
};

const postDocumentoEtapaRecursoRevision = async (id_etapa_proceso, params) => {
  return await http_client(
    `/api/v1/procesos/${id_etapa_proceso}/etapas/actual/archivo/recursos-revision`,
    params,
    "post"
  );
};

const postConsultas = async (params = {}, id) => {
  try {
    return await http_client(
      `/api/v1/procesos/${id}/consultas`,
      params,
      "post"
    );
  } catch (error) {
    return error.response;
  }
};

const descargarDocumentoConstulas = async (id_proceso_compra, ruta) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/consultas/documento`,
    {
      ruta: ruta,
    },
    "get",
    {},
    true,
    "blob"
  );
};

const putConsultas = async (params = {}, id) => {
  try {
    return await http_client(`/api/v1/procesos/consultas/${id}`, params, "put");
  } catch (error) {
    return error.response;
  }
};
const getAdendasEnmiedas = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/etapas/actual`,
    params,
    "get"
  );
};
const postDocumentoAdendas = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/procesos/compras/adendas/${id_proceso_compra}/archivo`,
    params,
    "post"
  );
const getDocumentRecursoRevision = async (
  id_proceso_compra,
  id_documento,
  params
) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/etapas/actual/archivo/recursos-revision/${id_documento}`,
    params,
    "get",
    {},
    true,
    "arraybuffer"
  );
};

const getDetalleAperturaOferta = async (
  id_proceso_compra,
  id_sub_proceso_obs,
  id_proveedor
) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/apertura/${id_sub_proceso_obs}?id_proveedor=${id_proveedor}`
  );
};

const getProveedoresAperturaOferta = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/proveedores`
  );
};

const getObsAperturaOferta = async (id_proceso_compra) => {
  return await http_client(`/api/v1/procesos/compra/${id_proceso_compra}/obs`);
};

const getSolicitudes = async (filters = {}) =>
  await http_client("/api/v1/compras/solicitudes/detalle/insumo", filters);
const getListProcesos = async (filters = {}) =>
  await http_client("/api/v1/procesos/obs/detalle", filters);

// const getProcesoReporte = (filters = {}) => http_client(
//     "/api/v1/procesos/obs/detalle/reporte",
//     filters,
//     "get",
//     {},
//     true,
//     "blob"
// );

const generarReporteProceso = async (params = {}) =>
  await http_client(
    `/api/v1/procesos/cron-job/reporte?now=true`,
    params,
    "post"
  );

const generarReporteProcesoEjecucion = async (params = {}) =>
  await http_client(
    `/api/v1/procesos/cron-job/reporte/ejecucion?now=true`,
    params,
    "post"
  );

const generarReporteProcesoContratacion = async (params = {}) =>
  await http_client(
    `/api/v1/procesos/cron-job/reporte/contratacion?now=true`,
    params,
    "post"
  );

const getModalidades = async (filters = {}) =>
  await http_client("/api/v1/forma/contratacion", filters);
const getEstados = async (filters = {}) =>
  await http_client("/api/v1/procesos/estados", filters);
const getEtapaActual = async (id, filters = {}) =>
  await http_client(`/api/v1/procesos/${id}/etapas/actual`, filters, "get");
const getEtapaActualPaginacion = async (id, filters = {}) =>
  await http_client(`/api/v1/procesos/${id}/etapas/actual`, filters);
const postProcesos = async (params = {}) =>
  await http_client("/api/v1/compras/proceso/obs", params, "post");

// etapa subasta
const getSubasta = async (id_subasta, params = {}) =>
  http_client(
    `/api/v1/subasta/${id_subasta}`,
    params,
    "get",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );
const getGanador = async (id_subasta, params = {}) =>
  http_client(
    `/api/v1/subasta/${id_subasta}/ganador`,
    params,
    "get",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );
const postOferta = async (id_subasta, params = {}) =>
  await http_client(
    `/api/v1/subasta/ofertar/${id_subasta}`,
    params,
    "post",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );
const postOfertaPorcentual = async (id_subasta, params = {}) =>
  await http_client(
    `/api/v1/subasta/ofertar/${id_subasta}/porcentual`,
    params,
    "post",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );

const getLastOferta = (id_subasta, params) => http_client(
    `/api/v1/subasta/${id_subasta}/ultimas/ofertas`,
    params,
    "get",
    {},
    true,
    "json",
    false,
    null,
    false,
    false
  );

const getLastOfertaExcel = async (id_subasta) =>
  http_client(
    `/api/v1/subasta/${id_subasta}/ultimas/ofertas/excel`,
    {},
    "get",
    {},
    true,
    "blob"
  );

// etapa contratacion
const getOBSPart = async (filters = {}) =>
  await http_client(`/api/v1/participaciones/obs`, filters);
const getSubProcPart = async (filters = {}) =>
  await http_client(`/api/v1/participaciones/subprocesos`, filters);
const getParticpaciones = async (id_usuario, id_proceso, filters = {}) =>
  await http_client(
    `/api/v1/participaciones/${id_usuario}/procesos/${id_proceso}`,
    filters
  );
const getParticUACI = async (id, filters = {}) =>
  await http_client(`/api/v1/participaciones/ganadores/${id}`, filters);
const getParticOferta = async (id, filters = {}) =>
  await http_client(
    `/api/v1/participaciones/ganador/oferta-presentada/${id}`,
    filters
  );
const getEvaluacionPaac = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/etapas/actual`,
    params,
    "get"
  );
};
const getObsUaci = async (id_proceso_compra) => {
  return await http_client(`/api/v1/procesos/compra/${id_proceso_compra}/obs`);
};

const postDocumentoRecursoRevisionUaci = async (
  id_proceso_compra,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/evaluacion/documento`,
    params,
    "post"
  );
};
// rechazo de proveedor
const getRechazo = async (id_proceso_compra) =>
  await http_client(`/api/v1/rechazos/proveedores/${id_proceso_compra}`, {});
const postRechazo = async (id_proceso_compra) =>
  await http_client(
    `/api/v1/rechazos/proveedores/${id_proceso_compra}`,
    {},
    "post"
  );

// etapa resultados preliminares
const getParticipacionResultados = async (id) =>
  await http_client(`/api/v1/procesos/participaciones/${id}`);

const getListarProveedores = async (params = {}) => {
  return await http_client(`/api/v1/proveedores/listar`, params);
};

const postInvitarProveedor = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/proveedores/${id_proceso_compra}/registrar-invitacion?correo=${params.correo}`,
    params,
    "post"
  );
};
//Listar ofertas
const getOfertasDetalle = async (id_proceso_obs, params) => {
  return await http_client(
    `/api/v1/procesos/compra/subprocesos/${id_proceso_obs}`,
    params,
    "get"
  );
};

const getEvaluacionUaciOferta = async (id, params) => {
  return await http_client(
    `/api/v1/procesos/subproceso/${id}/ofertas`,
    params,
    "get"
  );
};

const actualizarSubProcesoObs = async (id, params) =>
  await http_client(`/api/v1/procesos/subproceso/${id}/oferta`, params, "put");

const obtenerProveedorParticipacionObs = async (id, params) =>
  await http_client(`/api/v1/procesos/participacion/${id}`);

//endpoints configuracin subasta inversa
const getSalasConfiguracionSubasta = async (procesoId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/${procesoId}/salas`,
    params,
    "get"
  );
};
const postSalasConfiguracionSubasta = async (procesoId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/${procesoId}/salas`,
    params,
    "post"
  );
};
const putSalasConfiguracionSubasta = async (salaId, params = {}) => {
  return await http_client(`/api/v1/procesos/salas/${salaId}`, params, "put");
};
const deleteSalaConfiguracionSubasta = async (salaId) => {
  return await http_client(`/api/v1/procesos/salas/${salaId}`, {}, "delete");
};
// fin endpoints de configuracin subasta inversa

//endpoint configuracion sala
const getConfiguracionSala = async (salaId, params = {}) => {
  return await http_client(`/api/v1/procesos/salas/${salaId}`, params, "get");
};
const getProcesoSalaObs = async (salaId) => {
  return await http_client(`/api/v1/procesos/salas/${salaId}/obs`, {}, "get");
};
const getProcesoSalaPresentacion = async (salaId) => {
  return await http_client(
    `/api/v1/procesos/salas/${salaId}/presentaciones`,
    {},
    "get"
  );
};
const getProcesoSalaUnidades = async (salaId) => {
  return await http_client(
    `/api/v1/procesos/salas/${salaId}/unidades`,
    {},
    "get"
  );
};
const getDetalleSolicitudes = async (salaId, params) => {
  return await http_client(
    `/api/v1/procesos/salas/${salaId}/detalles`,
    params,
    "get"
  );
};
const postCrearSubasta = async (salaId, params = {}) => {
  return await http_client(`/api/v1/procesos/salas/${salaId}`, params, "post");
};
const deleteSubasta = async (subastaId) => {
  return await http_client(
    `/api/v1/procesos/subastas/${subastaId}`,
    {},
    "delete"
  );
};
const getConfigSubasta = async (subastaId) => {
  return await http_client(`/api/v1/procesos/subastas/${subastaId}`, {}, "get");
};
const editConfiguracionSubasta = async (subastaId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/subastas/${subastaId}`,
    params,
    "put"
  );
};

//fin endpoint configuracion sala

//configuracion recepcion de ofertas
const getRecepcionOfertas = async (procesoCompraId) => {
  return await http_client(
    `/api/v1/procesos/compra/${procesoCompraId}/recepcion-oferta`,
    {},
    "get"
  );
};

const putFechasRecepcionOfertas = async (procesoCompraId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${procesoCompraId}/recepcion-oferta`,
    params,
    "put"
  );
};

const getObsRecepcionOfertas = async (procesoObsId) => {
  return await http_client(
    `/api/v1/procesos/compra/recepcion-oferta/proceso-obs/${procesoObsId}`,
    {},
    "get"
  );
};

const getObsDetalleRecepcionOfertas = async (procesoCompraId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/${procesoCompraId}/recepcion-oferta/proceso-obs/detalles`,
    params,
    "get"
  );
};

const getSubprocesoObsRecepcionOfertas = async (
  idProcesoCompra,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/compra/proceso-obs/${idProcesoCompra}/sub-procesos`,
    params,
    "get"
  );
};

const postSubprocesoObsRecepcionOfertas = async (
  idProcesoCompra,
  params = {}
) => {
  return await http_client(
    `/api/v1/procesos/compra/proceso-obs/${idProcesoCompra}/sub-proceso`,
    params,
    "post"
  );
};

const getInfoSubprocesoObs = async (subprocesoId) => {
  // configuracion recepcion de oferta
  return await http_client(
    `/api/v1/procesos/compra/obs/sub-proceso/${subprocesoId}`,
    {},
    "get"
  );
};

const putSubprocesoRecepcionOferta = async (subprocesoId, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/obs/sub-proceso/${subprocesoId}`,
    params,
    "put"
  );
};

const deleteSubprocesoRecepcionOferta = async (subprocesoId) => {
  return await http_client(
    `/api/v1/procesos/compra/obs/sub-proceso/${subprocesoId}`,
    {},
    "delete"
  );
};
//fin configuracion recepcion de ofertas

const publicarProcesoPaac = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/estados`,
    params,
    "put"
  );
};

// Obtiene el listado de proveedores invitados a un proceso de compra
const getProveedoresInvitados = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/proveedores/${id_proceso_compra}/invitados`,
    { pagination: true },
    "get"
  );
};

const getMostrarProveedor = async (id_proveedor) => {
  return await http_client(
    `/api/v1/proveedores/mostrar/${id_proveedor}`,
    {},
    "get"
  );
};

const putEstadoProceso = async (id_proceso_compra, params) => {
  return await http_client(
    `/api/v1/procesos/${id_proceso_compra}/estados`,
    params,
    "put"
  );
};

const getProveedoresParticipantes = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/proveedores/${id_proceso_compra}/partipicantes`
  );
};
const getResumenEtapas = async (id_proceso_compra) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/etapas/tab`
  );
};
const getResumenEtapasDetalle = async (id_proceso_compra, id_etapa, params) => {
  return await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/etapas/${id_etapa}/detalles`,
    params
  );
};

const getRatingOfertaPresentada = async (id_proveedor) =>
  await http_client(
    `/api/v1/proveedores/${id_proveedor}/calificaciones`,
    {},
    "get"
  );
const getProviderDetails = async (id_proveedor) =>
  await http_client(`/api/v1/proveedores/mostrar/${id_proveedor}`, {}, "get");
const getProviders = async (id_proveedor, id_proceso_compra, params) =>
  await http_client(
    `/api/v1/procesos/compra/${id_proceso_compra}/resultados/ofertas-presentadas/${id_proveedor}`,
    params,
    "get"
  );

const getProcesoSalasFecha = async (salaId, params = {}) =>
  await http_client(`/api/v1/procesos/salas/${salaId}/fechas`, params, "get");

const postOfertaGeneral = async (id_proceso_compra, params = {}) =>
  await http_client(
    `/api/v1/procesos/proveedores/oferta-general/${id_proceso_compra}`,
    params,
    "post"
  );

const getUsuariosProceso = async (params = {}) => {
  return await http_client(`/api/v1/users/usuarios/proceso`, params, "get");
};

const postParticipantePEO = async (params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/agregar/participante`,
    params,
    "post"
  );
};

const getParticipantesPEO = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/listar/participantes/${id_proceso}`,
    params,
    "get"
  );
};

const deleteParticipantePEO = async (id_participante, params = {}) => {
  return await http_client(
    `/api/v1/procesos/compra/eliminar/participante/${id_participante}`,
    params,
    "delete"
  );
};

const getSubastas = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/subasta/resumen/por/proceso/${id_proceso}`,
    params,
    "get"
  );
};

const generarAuditoriaSubasta = async (id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/subasta/cron-job/${id_subasta}/auditoria`,
    params,
    "post"
  );
};

const getGanadorSubasta = async (id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/subasta/ganador/${id_subasta}`,
    params,
    "get"
  );
};

const adjudicarSubastas = async (id_proceso, id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/subasta/ganador/adjudicacion/${id_proceso}/${id_subasta}`,
    params,
    "post"
  );
};

const obtenerGanadores = async (id_proceso, params = {}) => {
  return await http_client(
    `/api/v1/subasta/ganador/listado/${id_proceso}`,
    params,
    "get"
  );
};

const enviarCorreosGanadores = async (id_subasta, params = {}) => {
  return await http_client(
    `/api/v1/subasta/ganador/correo/${id_subasta}`,
    params,
    "post"
  );
};

const validarCambioModalidad = async (id_proceso_compra, params = {}) => {
  return await http_client(
    `/api/v1/procesos/validar/cambio/modalidad/${id_proceso_compra}`,
    params,
    "get"
  );
};

const cambiarModalidad = (id_proceso_pac, params = {}) =>
  http_client(
    `/api/v1/procesos/cambiar/modalidad/${id_proceso_pac}`,
    params,
    "patch",
    {},
    true,
    "",
  );
const crearProcesoConjunto = (params = {}) =>
  http_client(
    `/api/v1/procesos/crear/proceso/compra/conjunto/`,
    params,
    "post",
    {},
    true,
    "",
    false
  );

// Cambio de estado
const cargarEstadosProceso = () =>
  http_client_with_status("/api/v1/procesos/obtener/estados", null, "get");

const cambiarEstadoProceso = (idProceso, data) =>
  http_client_with_status(
    `/api/v1/procesos/cambiar/estado/${idProceso}`,
    data,
    "patch"
  );

const catalogoSubasta = async (params = {}) => {
  return await http_client(`/api/v1/subasta/catalogo/subastas/`, params, "get");
};
const montoTotalLote = async (id, params = {}) => {
  return await http_client(`/api/v1/subasta/total/lote/${id}`, params, "get");
};
const procesosObsLote = async (id, params = {}) => {
  return await http_client(`/api/v1/subasta/procesos/obs/${id}`, params, "get");
};

const obtenerProcesosConjuntos = async (params) =>
  http_client(
    "/api/v1/procesos/obtener/procesos/compra/conjunta",
    params,
    "get"
  );
const getProcesoCompra = async (id) =>
  http_client(`/api/v1/procesos/obtener/proceso/compra/${id}`, {}, "get");
const getProcesosCompra = async (idInstitucion, idFormaContratacion) =>
  http_client(
    `/api/v1/procesos/obtener/procesos/compra/${idInstitucion}/${idFormaContratacion}`,
    {},
    "get"
  );

const agregarProcesoHijo = async (idProcesoPadre, idProcesoHijo) =>
  http_client(
    `/api/v1/procesos/compra/conjunta/${idProcesoPadre}/${idProcesoHijo}`,
    {},
    "patch"
  );
const agregarAdministradorProcesoConjunto = async (
  id_proceso_conjunto,
  id_institucion,
  params = {}
) =>
  http_client(
    `/api/v1/procesos/compra/conjunta/crear/administrador/${id_proceso_conjunto}/${id_institucion}`,
    params,
    "post"
  );
const removerAdministradorProcesoConjunto = async (
  id_proceso_conjunto,
  params = {}
) =>
  http_client(
    `/api/v1/procesos/compra/conjunta/remover/administrador/${id_proceso_conjunto}`,
    params,
    "delete"
  );
const removerProcesoHijo = async (idProcesoHijo) =>
  http_client(
    `/api/v1/procesos/compra/conjunta/remover/${idProcesoHijo}`,
    {},
    "patch"
  );
const removerModalidad = async (idModalidad) =>
  http_client(
    `/api/v1/procesos/compra/conjunta/remover/modalidad/${idModalidad}`,
    {},
    "patch"
  );
const agregarModalidad = async (idModalidad) =>
  http_client(
    `/api/v1/procesos/compra/conjunta/agregar/modalidad/${idModalidad}`,
    {},
    "patch"
  );

const cargarProcesosRelacionadosConPAC = (idProcesoCompra) => http_client_with_status(
  `/api/v1/procesos/procesos-relacionados-con-pac/${idProcesoCompra}`,
  null,
  'get',
);


const validarProcesoPublicado = async (id_proceso_compra) =>
    http_client(`/api/v1/procesos/validar/estado/publicado/${id_proceso_compra}`, {}, "get");
export default {
  getTecnicosProcesoCompra,
  getEtapaActual,
  getSolicitudes,
  getInfoGeneralProcesoCompra,
  getDocumentosProcesoCompra,
  postDocumentosProcesoCompra,
  deleteDocumentoProcesoCompra,
  downloadDocumentoProcesoCompra,
  getEtapasProcesoCompra,
  putEtapasProcesoCompra,
  postDocumentoEtapasProcesoCompra,
  getListProcesos,
  getModalidades,
  getEstados,
  postProcesos,
  getAdendasEnmiedas,
  getListaDocumentosProcesoCompra,
  putInforGeneralProcesoCompra,
  postConsultas,
  putConsultas,
  postDocumentoAdendas,
  participar,
  postDocumentoEtapaRecursoRevision,
  postDocumentoEtapasProcesoCompraProveedor,
  getDocumentRecursoRevision,
  getEtapaActualPaginacion,
  getDetalleAperturaOferta,
  getOBSPart,
  getSubProcPart,
  getParticpaciones,
  getParticUACI,
  getParticOferta,
  getEvaluacionPaac,
  getObsUaci,
  postDocumentoRecursoRevisionUaci,
  getProveedoresAperturaOferta,
  getObsAperturaOferta,
  getSubasta,
  postOferta,
  getRechazo,
  postRechazo,
  getParticipacionResultados,
  getGanador,
  getListarProveedores,
  postInvitarProveedor,
  getEvaluacionUaciOferta,
  actualizarSubProcesoObs,
  obtenerProveedorParticipacionObs,
  getOfertasDetalle,
  getSalasConfiguracionSubasta,
  postSalasConfiguracionSubasta,
  putSalasConfiguracionSubasta,
  deleteSalaConfiguracionSubasta,
  getConfiguracionSala,
  getProcesoSalaObs,
  getProcesoSalaPresentacion,
  getProcesoSalaUnidades,
  getDetalleSolicitudes,
  postCrearSubasta,
  deleteSubasta,
  getConfigSubasta,
  editConfiguracionSubasta,
  getRecepcionOfertas,
  putFechasRecepcionOfertas,
  getObsRecepcionOfertas,
  getObsDetalleRecepcionOfertas,
  getSubprocesoObsRecepcionOfertas,
  postSubprocesoObsRecepcionOfertas,
  getInfoSubprocesoObs,
  putSubprocesoRecepcionOferta,
  deleteSubprocesoRecepcionOferta,
  publicarProcesoPaac,
  getProveedoresInvitados,
  getMostrarProveedor,
  putEstadoProceso,
  getProveedoresParticipantes,
  getResumenEtapas,
  getResumenEtapasDetalle,
  getRatingOfertaPresentada,
  getProviderDetails,
  getProviders,
  getProcesoSalasFecha,
  postOfertaGeneral,
  descargarDocumentoConstulas,
  getUsuariosProceso,
  postParticipantePEO,
  getParticipantesPEO,
  deleteParticipantePEO,
  getLastOferta,
  getLastOfertaExcel,
  getSubastas,
  generarAuditoriaSubasta,
  getGanadorSubasta,
  adjudicarSubastas,
  obtenerGanadores,
  enviarCorreosGanadores,
  validarCambioModalidad,
  cambiarModalidad,
  cargarEstadosProceso,
  cambiarEstadoProceso,
  catalogoSubasta,
  montoTotalLote,
  postOfertaPorcentual,
  generarReporteProceso,
  crearProcesoConjunto,
  obtenerProcesosConjuntos,
  getProcesoCompra,
  getProcesosCompra,
  agregarProcesoHijo,
  removerProcesoHijo,
  removerModalidad,
  agregarModalidad,
  generarReporteProcesoEjecucion,
  generarReporteProcesoContratacion,
  agregarAdministradorProcesoConjunto,
  removerAdministradorProcesoConjunto,
  procesosObsLote,
  validarProcesoPublicado,
  cargarProcesosRelacionadosConPAC,
};
