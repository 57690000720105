import { http_client_slim } from "@/plugins/http_client";

const http_client = http_client_slim("/api/v1/notificaciones");

const http_client2 = http_client_slim("/api/v1/perfiles");

const getNotificaciones = async (params = {}) =>
  http_client("/", {
    data: params,
    enableLoader: false,
    showErrorAlert: false,
  });

const readAllNotifications = async (params = {}) =>
  http_client("/readed/all", { data: params, method: "PUT" });

const markAsRead = async (id_notificacion, params = {}) =>
  http_client(`/${id_notificacion}/readed`, {
    data: params,
    method: "PUT",
  });

const deleteNotification = async (id_notificacion, params = {}) =>
  http_client(`/${id_notificacion}`, {
    data: params,
    method: "delete",
  });

const deleteAllNotifications = async (params) =>
  http_client("/all", {
    data: params,
    method: "delete",
  });

const enviarNotificaciones = async (params = {}) =>
  http_client(`/enviar`, {
    data: params,
    method: "post",
  });

const enviarNotificacionesCorreo = async (params = {}) =>
  http_client(`/enviar/correo`, {
    data: params,
    enableLoader: false,
    showErrorAlert: false,
    method: "post",
  });

const listarPerfiles = async (params = { paginacion: false }) =>
  http_client2('/', {
    data: params,
    enableLoader: false,
    showErrorAlert: false,
  });

const almacenarImagen = async (imagen) =>
  http_client("/imagenes=guardar", {
    method: "post",
    data: imagen,
    showErrorAlert: false,
    enableLoader: false,
  });

export default {
  getNotificaciones,
  readAllNotifications,
  markAsRead,
  deleteNotification,
  enviarNotificaciones,
  listarPerfiles,
  deleteAllNotifications,
  enviarNotificacionesCorreo,
  almacenarImagen,
};
