import { http_client_with_status } from "@/plugins/http_client";

const obtenerTotalConvenios = () =>
  http_client_with_status("api/v1/resumen/total-convenios", null, "get");

const obtenerNumeroOrdenesCompra = () =>
  http_client_with_status("api/v1/resumen/numero-ordenes-compra", null, "get");

const obtenerCantidadProcesosAnio = () =>
  http_client_with_status("api/v1/resumen/cantidad-procesos-anio", null, "get");

const obtenerPorcentajePerfilProveedores = () =>
  http_client_with_status("api/v1/resumen/porcentaje-perfil-proveedores", null, "get");

const obtenerTipoInicioSesionUsuarios = () =>
  http_client_with_status("api/v1/resumen/tipo-sesion-usuarios", null, "get");

export default {
  obtenerTotalConvenios,
  obtenerNumeroOrdenesCompra,
  obtenerCantidadProcesosAnio,
  obtenerPorcentajePerfilProveedores,
  obtenerTipoInicioSesionUsuarios,
};
