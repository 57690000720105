import {http_client} from "@/plugins/http_client";


const getActividad = async( params ={}) =>
    await http_client(`/api/v1/actividades`, params,"get");

const getActividadesByProv = async (id_proveedor, params) =>
    await http_client(`/api/v1/actividades/proveedor/${id_proveedor}`, params);

const postActividad = async (data) =>
    await http_client(`/api/v1/actividades`, data, "post");

const deleteActividad = async (id_actividad, params) =>
    await http_client(`/api/v1/actividades/${id_actividad}`, params,"delete");

const getActividadProceso = async (id_proceso_pac, params) =>
    await http_client(`/api/v1/actividades/proceso-actividad/${id_proceso_pac}`, params);

const postActividadProceso = async (id_proceso_pac, data) =>
    await http_client(`/api/v1/actividades/proceso-actividad/${id_proceso_pac}`, data, "post");

const deleteActividadProceso = async (id_proceso_pac, id_actividad, params) =>
    await http_client(`/api/v1/actividades/proceso-actividad/${id_proceso_pac}/${id_actividad}`, params, "delete");

const getObs = async (params) =>
    await http_client(`/api/v1/actividades/listar/obs`, params, "get");

const listarGirosRubros = async (params) =>
    await http_client(`/api/v1/actividades/listar/giros-rubros`, params, "get");

export default{
    getActividad,
    getActividadesByProv,
    postActividad,
    deleteActividad,
    getActividadProceso,
    postActividadProceso,
    deleteActividadProceso,
    getObs,
    listarGirosRubros,
}
